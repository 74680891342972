import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Disclaimer = () => (
  <Layout>
    <SEO title="Disclaimer" />
    <div class="pt-24">
      <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <h3>Disclaimer</h3>
        <p>Op het gebruik van deze website (www.jcidendermonde.be) zijn onderstaande gebruiksvoorwaarden van toepassing.
            Door gebruik te maken van deze website, wordt u geacht kennis te hebben genomen van de gebruiksvoorwaarden en deze te hebben aanvaard.
        </p>
        <h4>Gebruik van de informatie</h4>
        <p>JCI Dendermonde streeft ernaar op deze website altijd juiste en actuele informatie aan te bieden. Hoewel deze informatie met de grootst mogelijke zorgvuldigheid is samengesteld, staat JCI Dendermonde niet in voor de volledigheid, juistheid of actualiteit van de informatie. 
            De juridische informatie op de website is van algemene aard en kan niet worden beschouwd als een vervanging van juridisch advies.</p>
        <p>Aan de informatie kunnen geen rechten worden ontleend. JCI Dendermonde aanvaardt geen aansprakelijkheid voor schade die voortvloeit uit het gebruik van de informatie of de website en evenmin voor het niet goed functioneren van de website. 
            Op basis van het verzenden en ontvangen van informatie via de website of via e-mail kan niet zonder meer een relatie tussen JCI Dendermonde en de gebruiker van de website ontstaan.</p>
        <h4>E-mail</h4>
        <p>JCI Dendermonde garandeert niet dat aan haar gezonden e-mails (tijdig) worden ontvangen of verwerkt, omdat tijdige ontvangst van e-mails niet kan worden gegarandeerd.
            Ook de veiligheid van het e-mailverkeer kan niet volledig worden gegarandeerd door de hieraan verbonden veiligheidsrisico’s. Door zonder encryptie of wachtwoordbeveiliging per e-mail met JCI Dendermonde te corresponderen, accepteert u dit risico.</p>
        <h4>Hyperlinks</h4>
        <p>
            Deze website kan hyperlinks bevatten naar websites van derden. JCI Dendermonde heeft geen invloed op websites van derden en is niet verantwoordelijk voor de beschikbaarheid of inhoud daarvan.
            JCI Dendermonde aanvaardt dan ook geen aansprakelijkheid voor schade die voortvloeit uit het gebruik van websites van derden.
        </p>
        <h4>Intellectuele eigendomsrechten</h4>
        <p>Alle publicaties en uitingen van JCI Dendermonde zijn beschermd door auteursrecht en andere intellectuele eigendomsrechten. Behalve voor persoonlijk en niet commercieel gebruik, mag niets uit deze publicaties en uitingen op welke manier dan ook verveelvoudigd, gekopieerd of op een andere manier openbaar worden gemaakt, zonder dat JCI Dendermonde daar vooraf schriftelijke toestemming voor heeft gegeven.</p>
      </div>
      </div>
  </Layout>
)

export default Disclaimer
